import { Settings } from '../../hooks/useSettings/useSettings.type'
import { Item } from '../../types'
import { Option } from '../common/form/fields/dropdown/dropdown.type'
import { Filters } from '../../hooks/useFilters/useFilters.type'

export type CheckoutModalProps = {
   isModalOpen: boolean
   handleClose: () => void
   checkoutAction: any
   selectedItems: Item[]
   maxWidth?: string
   zIndex?: string
   handlePageCancelAndReset?: () => void
   viewTitle?: boolean
   isAdminCompanyUser?: boolean
   quantityInCart?: number
}

export type CartOptions = {
   connection: string | null
   dueBackDate: string
   location: string | null
}

export type BorrowViewProps = {
   handleDropdownChange: (value: string, option: any) => void
   handleDateChange: (value: string, date: any) => void
   locationOptions: Option[]
   cartOptions: CartOptions
   settings: Settings
}

export type LoanAndFieldTransferViewProps = {
   validationErrors: Record<string, string>
   handleDropdownChange: (value: string, option: any) => void
   connectionOptions: Option[]
   locationOptions: Option[]
   handleDateChange: (value: string, date: any) => void
   cartOptions: CartOptions
   settings: Settings
}

export type SuccessViewProps = {
   itemCount: number
   view: string
   connectionName?: string
   dueBackDate?: string
   isAdminCompanyUser?: boolean
}

export type WarehouseTransferViewProps = {
   validationErrors: Record<string, string>
   handleDropdownChange: (value: string, option: any) => void
   locationOptions: Option[]
}

export type ActionsFilterPanelProps = {
   isFilterPanelOpen: boolean
   handleClose: () => void
   applySavedFilters: (filterObject: Filters, toolStatusSuffix?: any) => void
   isPageFilterReset?: boolean
   setIsPageFilterReset?: (bool: boolean) => void
   isEmployeeUser
   selectedActionQuickFilter: any
}

export type AddAllToCartButtonProps = {
   onClick: () => void
   text?: string
}

export type AddToCartButtonProps = {
   cell: any
   action: (id?: any) => void
   isKitItem: boolean
   isItemInCart: boolean
}

export type CartButtonProps = {
   onClick: () => void
   text?: string
   iconId?: string
   disabled?: boolean
}

export type QuantityModalProps = {
   isModalOpen: boolean
   handleClose: () => void
   handleAdjustTotal: (item: Item, val: string) => void
   handleCustomTotalChange: (e: any, item: Item) => void
   handleAddQuantityItemsToCart: (quantityItems: Item[]) => void
   getQuantityItemsFromPage: (array: Item[]) => Item[]
   maxWidth?: string
   items: Item[]
   zIndex?: string
   isEdit?: boolean
   selectedActionQuickFilter: any
}

// enums
export enum View {
   Loan = 'Loan',
   Return = 'Return',
   Field_Transfer = 'Field Transfer',
   Warehouse_Transfer = 'Warehouse Transfer',
   Borrow = 'Borrow',
   MakeAvailable = 'Make Available',
   MakeUnavailable = 'Make Unavailable',
}

// matches the backend requestType enum to display virtual connections or not
export enum ConnectionRequestType {
   LoanRequest = 1,
   TransferRequest = 2,
}
