import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI } from '..';

import { UseNotificationTotals, NotificationTotalsResponse } from './useNotificationTotals.type';

export default function(): UseNotificationTotals {
    const request = useAPI();
    const { data, isLoading, isRefetching, refetch } = useQuery('notification-totals', getTotalsFromAPI);

    async function getTotalsFromAPI(): Promise<AxiosResponse<NotificationTotalsResponse>> {
        return request.get(PATH.DASHBOARD.NOTIFICATION_TOTALS);
    }

    const {
        ActionTasks = 0,
        InformationalTasks = 0,
        ReminderTasks = 0,
    } = data?.data?.Data || {};
    const totalNotifications = ActionTasks + InformationalTasks + ReminderTasks;

    const refresh = () => {
        refetch();
    }

    return {
        isLoading: isLoading || isRefetching,
        refresh,
        totals: {
            ActionTasks,
            InformationalTasks,
            ReminderTasks
        },
        totalNotifications,
    }
}