import React from 'react';

import * as styles from './noResults.module.scss';

const NoResults: React.FC = ({ children }) => (
    <div className={styles.wrapper}>
        {children}
    </div>
);

export default NoResults;