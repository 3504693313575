import React from 'react'
import '../../../assets/sass/components/Table/Index.scss'
import { TABLE } from '../../../constants/tables'
import * as styles from './table.module.scss'
import { TableProps } from './types'

const Table = ({
   totalCount,
   getTableProps,
   headerGroups,
   getTableBodyProps,
   page,
   prepareRow,
   rowAction,
   hideResultsTitle = false,
   isCart,
}: TableProps) => (
   <>
      {!hideResultsTitle && (
         <div className={styles.tableTitle}>
            {totalCount} {TABLE.COUNT_LABEL}
         </div>
      )}
      <div className="table-scroller">
         <table {...getTableProps()}>
            <thead>
               {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                     {headerGroup.headers.map((column) => {
                        return (
                           <th
                              {...column.getHeaderProps({
                                 style: {
                                    textAlign: !!column.align
                                       ? column.align
                                       : 'left',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                 },
                                 width: !!column.minWidth
                                    ? column.minWidth
                                    : undefined,
                              })}
                           >
                              {column.render(TABLE.HEADER)}
                              <div
                                 {...column.getResizerProps()}
                                 className={styles.resizer}
                              />
                           </th>
                        )
                     })}
                  </tr>
               ))}
            </thead>
            <tbody {...getTableBodyProps()}>
               {page.map((row) => {
                  prepareRow(row)
                  return (
                     <tr
                        {...row.getRowProps()}
                        onClick={(e) =>
                           rowAction
                              ? rowAction(
                                   row.original.Id ?? row.original.ID,
                                   row.original.ToolLoanId,
                                   row.original.KitId,
                                   e,
                                   isCart
                                )
                              : null
                        }
                        className={
                           rowAction ? styles.pointer : styles.noPointer
                        }
                     >
                        {row.cells.map((cell) => {
                           return (
                              <td
                                 {...cell.getCellProps({
                                    style: {
                                       textAlign: !!cell.column.align
                                          ? cell.column.align
                                          : 'left',
                                       whiteSpace: 'nowrap',
                                    },
                                    width: !!cell.column.minWidth
                                       ? cell.column.minWidth
                                       : undefined,
                                 })}
                                 className={cell.column.id}
                              >
                                 {(!cell.column.allowBlank &&
                                    cell.value === '') ||
                                 cell.value === null ||
                                 cell.value === undefined
                                    ? '-'
                                    : cell.render(TABLE.CELL)}
                              </td>
                           )
                        })}
                     </tr>
                  )
               })}
            </tbody>
         </table>
      </div>
   </>
)

export default Table
