import { Profile } from '../../hooks/useProfile/useProfile.type'

enum PlanTypes {
   Starter = 'Starter',
   Trial = 'Trial',
   EnterpriseYearly = 'Annually',
   EnterpriseMonthly = 'Monthly',
}

const isStarterPlan = (profile?: Profile) =>
   profile && profile.PlanType === PlanTypes.Starter

const isEnterprisePlan = (profile?: Profile) =>
   profile &&
   (profile.PlanType === PlanTypes.EnterpriseYearly ||
      profile.PlanType === PlanTypes.EnterpriseMonthly)

const isTrialPlan = (profile?: Profile) =>
   profile && profile.PlanType === PlanTypes.Trial

export { PlanTypes, isStarterPlan, isEnterprisePlan, isTrialPlan }
