import React from 'react'
import * as styles from './crudItems.module.scss'
import { format } from 'date-fns'
import Svg from '../svg/svg'

interface Items {
   Action: String
   DateCreated: Date
   BorrowerName: String
   Quantity: String
   Data: String
}

interface Props {
   itemHistoryData: any[]
}

export const History: React.FC<Props> = ({ itemHistoryData }) => {
   const getIcon = (str: String) => {
      switch (str) {
         case 'Tool created':
            return (
               <Svg
                  id="plus"
                  overrideViewBox="0,-0.5,8,8"
                  width="30"
                  height="30"
               />
            )
         case 'Tool loaned':
            return <Svg id="loaned" width="30" height="30" />
         case 'Tool returned':
            return (
               <Svg
                  id="returned"
                  overrideViewBox="30,0,560,560"
                  width="37"
                  height="30"
               />
            )
         case 'Tool returned by owner':
            return (
               <Svg
                  id="returned to owner"
                  overrideViewBox="0,0,560,560"
                  width="37"
                  height="30"
               />
            )
         case 'Tool location updated':
            return (
               <Svg
                  id="location"
                  overrideViewBox="-2,0,20,30"
                  width="37"
                  height="30"
               />
            )
         case 'Tool transferred':
            return <Svg id="plus" overrideViewBox="-0.5,-0.5,8,8" />
         default:
            return (
               <Svg
                  id="calendar"
                  overrideViewBox="0.3,0,7,2"
                  width="30"
                  height="30"
               />
            )
      }
   }

   return (
      <>
         {itemHistoryData &&
            itemHistoryData.map((item: Items, index: number) => {
               return (
                  <div className={styles.itemHistoryContainer}>
                     <div className={styles.row}>
                        <div className={styles.column}>
                           <div className={styles.timeline} />
                           {getIcon(item.Action)}
                        </div>
                        <div className={styles.column}>
                           <h2>
                              {format(
                                 new Date(item.DateCreated),
                                 'EEE, dd MMM yyyy'
                              )}{' '}
                              <span>{item.BorrowerName}</span>
                           </h2>
                           {item.Action && <p>{item.Action}</p>}
                           <p>{item.Data}</p>
                           {Boolean(item.Quantity) && (
                              <p>Quantity: {item.Quantity}</p>
                           )}
                        </div>
                     </div>
                  </div>
               )
            })}
      </>
   )
}
