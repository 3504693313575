import { useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '../'

import {
   Settings,
   SettingsType,
   UseSettingsData,
   UseSettingsOptions,
} from './useSettings.type'

const cacheIdentifier = 'settings'

function getAPIEndpoint(type: SettingsType): string {
   switch (type) {
      case 'item-record':
         return PATH.SETTINGS.UPDATE_ITEM_RECORD
      case 'notifications':
         return PATH.SETTINGS.UPDATE_NOTIFICATIONS
      default:
         return PATH.SETTINGS.UPDATE_SYSTEM
   }
}

export default function (options?: UseSettingsOptions): UseSettingsData {
   const [error, setErrors] = useState('')
   const request = useAPI()
   const {
      data,
      isLoading,
      refetch: refresh,
   } = useQuery<AxiosResponse<Settings>>(cacheIdentifier, getSettingsFromAPI)
   const {
      isLoading: mutationLoading,
      isSuccess,
      mutate,
   } = useMutation(updateSystemSettingsAPI, {
      onSuccess: () => {
         refresh()
      },
      onError: (error: AxiosError<{ Message: string }>) => {
         setErrors(
            error.response.data.Message || 'There was an unknown server error'
         )
      },
   })

   async function getSettingsFromAPI(): Promise<AxiosResponse<Settings>> {
      return await request.get(PATH.SETTINGS.LIST)
   }

   async function updateSystemSettingsAPI(
      settings: Settings
   ): Promise<AxiosResponse> {
      const endpoint = getAPIEndpoint(options?.type)

      return await request.post(endpoint, { ...settings })
   }

   const update = (settings: Settings) => {
      setErrors('')
      mutate(settings)
   }

   return {
      error,
      isLoading,
      isSuccess,
      isUpdating: mutationLoading,
      refresh,
      settings: data?.data || {},
      update,
   }
}
