import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Col, Row } from 'react-grid-system'
import { ClearFix } from '../../clearfix/clearfix'
import { IoCloseSharp as CloseIcon } from 'react-icons/io5'
import { HiCheck as AcceptIcon } from 'react-icons/hi'
import { Option } from '../../form/fields/dropdown/dropdown.type'
import { Button, Image, KeyValue, LoadingOverlay, LocationLabel } from '../..'
import { SnackBar } from '../../../common'
import { Notification, Severity } from '../../../../types'
import { Item } from '../../../../hooks/useUserItems/useUserItems.type'
import { Props } from './notificationItem.type'
import * as styles from './notificationItem.module.scss'
import { AddEditNoteModal } from '../../../views/notes/addEditNoteModal' 
import { useAPI, useItemNotes } from '../../../../hooks'
import { PATH } from '../../../../constants/global'
import { NoteType } from '../../../../hooks/useNoteTypes/useNoteTypes.type'

const NotificationItem: React.FC<Props> = ({
   notification,
   onAcceptDecline,
   onDismiss,
}) => {
   const request = useAPI();
   const [isDismissing, setDismissing] = useState(false)

   const {
      data: itemNotesData,
      itemNotesList,
      refetch: refetchNotes
   } = useItemNotes()
   const [isNoteModalVisible, setIsNoteModalOpen] = useState<boolean>(false)
   const [selectedNote, setSelectedNote] = useState<any>(undefined)
   const [selectedItem, setSelectedItem] = useState<Item>(undefined)
   const [selectedNoteType, setSelectedNoteType] = useState<Option>(undefined)
   const [updateStatus, setUpdateStatus] = useState({
      IS_UPDATE_SUCCESS: false,
      IS_UPDATE_ERROR: false,
   })
   const [noteTypes, setNoteTypes] = useState<NoteType[]>([])
   const [items, setItems] = useState<Item[]>([])

   useEffect(() => {
      // if notification has a tool associated go and get any notes for that tool
      if (notification.ToolId) {
         itemNotesList(notification?.ToolId?.toString())
      }
   }, [notification])

   const handleNoteClick = async (selectedNoteId: number) => {
      itemNotesList(notification.ToolId.toString())
      const noteSelected: any = itemNotesData.find(
         (note: any) => note.Id === selectedNoteId
      )
      await updateUserItems(noteSelected?.ToolId?.toString())
      await updateNoteTypes(noteSelected?.NoteTypeId)
      setSelectedNote(noteSelected)
      setIsNoteModalOpen(true)
   }

   const updateUserItems = async (toolId: string) => {
      const userItems: Item[] = await request.get(PATH.USER_ITEMS.LIST);
      setItems(userItems)
      if(userItems?.length) {
         const item = userItems.find(
            (item) => item.ID === toolId
         )
         setSelectedItem(item)
      }

   }

   const updateNoteTypes = async (noteTypeId: number) => {
      const nTypes: NoteType[] = await request.get(PATH.NOTE_TYPES.LIST);
      setNoteTypes(nTypes)
      if(nTypes?.length) {
         const noteType = nTypes.find(
            (noteType) => noteType.Id === noteTypeId
         )
         setSelectedNoteType({
            value: noteType?.Id.toString(),
            label: noteType?.Title,
         })
      }
   }

   const getUpdateStatus = (status: any): void => {
      setUpdateStatus(status)
   }

   const handleDismiss = () => {
      setDismissing(true)
      onDismiss && onDismiss(notification.Id)
   }

   const handleAcceptDecline = (id: Notification['Id'], accept: boolean) => {
      setDismissing(true)
      onAcceptDecline && onAcceptDecline(id, accept)
   }

   const renderNotificationImage = () => (
      <Image
         className={styles.image}
         defaultImagePadding={10}
         padding={3}
         url={notification?.ImageUrl}
         alt=""
         width={75}
      />
   )

   const keyValueBreakpoints = {
      xs: 6,
      sm: 4,
      md: 6,
      lg: 12,
      xl: 4,
      xxl: 4,
   }

   const linkToNote = !!notification.ToolNoteId;

   return (
      <>
       {selectedNote && (
         <ClearFix>
            <AddEditNoteModal
               selectedNote={selectedNote}
               setSelectedNote={setSelectedNote}
               isEditModalOpen={isNoteModalVisible}
               setIsEditModalOpen={setIsNoteModalOpen}
               selectedItem={selectedItem}
               setSelectedItem={setSelectedItem}
               selectedNoteType={selectedNoteType}
               setSelectedNoteType={setSelectedNoteType}
               getUpdateStatus={getUpdateStatus}
               refetchNotes={refetchNotes}
               noteTypes={noteTypes}
               items={items}
            />
         </ClearFix>
         )}
         <SnackBar
            message={
               updateStatus?.IS_UPDATE_SUCCESS
                  ? 'Note Updated'
                  : 'Error Updating Note'
            }
            open={
               updateStatus?.IS_UPDATE_SUCCESS || updateStatus?.IS_UPDATE_ERROR
            }
            severity={
               updateStatus?.IS_UPDATE_SUCCESS
                  ? Severity.SUCCESS
                  : Severity.ERROR
            }
            disableAutoClose={false}
         />
         <div className={styles.wrapper}>

            {linkToNote ? (
               <div>{renderNotificationImage()}</div>
            ) : (
               renderNotificationImage()
            )}

            <div className={styles.content}>
               <div className={styles.description}>
                  {!!linkToNote ? 
                     <div
                        className={styles.notificationNoteWrapper}
                        onClick={() =>
                           handleNoteClick(notification?.ToolNoteId)
                        }
                     >
                        {ReactHtmlParser(notification.Message)}   
                     </div>   
                      :  ReactHtmlParser(notification.Message)   
                  }
               </div>

               {notification?.Location && (
                  <div className={styles.location}>
                     <LocationLabel label={notification.Location} />
                  </div>
               )}

               <div className={styles.infoItems}>
                  <Row wrap="wrap">
                     {notification?.Quantity > 1 && (
                        <Col {...keyValueBreakpoints}>
                           <KeyValue
                              className={styles.keyValue}
                              label="Quantity"
                              value={notification.Quantity}
                           />
                        </Col>
                     )}

                     {!!notification?.SubDetail && (
                        <Col {...keyValueBreakpoints}>
                           <KeyValue
                              className={styles.keyValue}
                              label="Due back"
                              value={notification.SubDetail}
                           />
                        </Col>
                     )}

                     <Col {...keyValueBreakpoints}>
                        <KeyValue
                           className={styles.keyValue}
                           label="Task created"
                           value={notification.DateCreated}
                        />
                     </Col>
                  </Row>
               </div>
            </div>

            {onAcceptDecline && (
               <span className={styles.acceptDecline}>
                  <Button
                     className={styles.acceptDeclineButton}
                     onClick={() => handleAcceptDecline(notification.Id, true)}
                     minWidth="0"
                     small
                     variant="success"
                     title="Accept"
                  >
                     <AcceptIcon />
                  </Button>
                  <Button
                     className={styles.acceptDeclineButton}
                     onClick={() => handleAcceptDecline(notification.Id, false)}
                     minWidth="0"
                     small
                     variant="danger"
                     title="Reject"
                  >
                     <CloseIcon />
                  </Button>
               </span>
            )}

            {onDismiss && (
               <span className={styles.dismiss}>
                  <button onClick={handleDismiss} title="Dismiss">
                     <CloseIcon />
                  </button>
               </span>
            )}

            {isDismissing  || 
               (notification?.ToolId  && !itemNotesData) && 
               <LoadingOverlay positionAbsolute xs />}
         </div>

      </>
   )
}

export default NotificationItem
