import React, { ReactNode, useEffect, useMemo } from 'react'
import '../App.scss'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { useLogin } from '../hooks'
import { useProfile } from '../hooks'
import { getRolesByRoute } from '../constants/routes'
import { isLoggedIn, getUser } from '../common/utils/auth'
import { isBrowser } from '../common/utils/functions'
import { getRedirectAfterLoginURL } from '../common/utils/url'
import { LoadingOverlay } from './common'
import { Paths } from '../constants/structure'

import UnauthorisedView from './views/401'

import { isCompanyRole } from '../common/utils/roles'
import { isEnterprisePlan, isStarterPlan } from '../common/utils/planTypes'
import {
   routesForExpiredEnterprise,
   routesForCompanyStarter,
} from '../common/utils/access'

type AppProps = {
   children: ReactNode
}

export default function ({ children }: AppProps) {
   const loggedIn = isLoggedIn()
   const { pathname } = useLocation()
   const { generateNewToken } = useLogin()
   const { profile, isLoading, isFetching } = useProfile()

   // on load if still logged in check token for expiry within one month
   useEffect(() => {
      if (isLoggedIn()) {
         const user = getUser()
         const expiryDate = user['.expires']

         const reissueDate = new Date(expiryDate).setMonth(
            new Date(expiryDate).getMonth() - 1
         )

         if (new Date() > new Date(reissueDate)) {
            generateNewToken(user?.refresh_token)
         }
      }
   }, [])

   // get roles for current route, empty array is public
   const routeRoles = getRolesByRoute(pathname)
   const userRoles = profile?.Roles ? profile.Roles : []
   const isCompany = useMemo(() => isCompanyRole(userRoles), [userRoles])
   const isStarter = useMemo(() => isStarterPlan(profile), [profile])
   const isEnterprise = useMemo(() => isEnterprisePlan(profile), [profile])

   // user tries to access route URL, redirect them to dashboard if they're logged in
   if (loggedIn && pathname === Paths.LANDING && !isLoading) {
      navigate(Paths.DASHBOARD)
      return <LoadingOverlay />
   }

   // page is not private
   if (!routeRoles?.length) {
      return children
   }

   // user trying to access a private page without being logged in
   // boot them to login screen
   if (routeRoles?.length && !loggedIn && isBrowser()) {
      navigate(getRedirectAfterLoginURL(pathname))
      return <LoadingOverlay />
   }

   // user is a starter employee or does not have the role needed to view this page
   if (
      (isStarter && !isCompany) ||
      (userRoles?.length &&
         !userRoles.some((role) => routeRoles.includes(role)))
   ) {
      return <UnauthorisedView isLoggedIn={loggedIn} />
   }

   // user is a starter or enterprise with the company role
   if (
      userRoles?.length &&
      isCompany &&
      !isLoading &&
      !isFetching &&
      (isStarter || isEnterprise)
   ) {
      const companyStarter =
         isStarter && !routesForCompanyStarter.map(String).includes(pathname)
      const expiredEnterprise =
         profile.EnterpriseUser === false &&
         !routesForExpiredEnterprise.map(String).includes(pathname)
      // redirect to subscriptions page
      if (companyStarter || expiredEnterprise) {
         navigate(Paths.MY_PROFILE_SUBSCRIPTIONS)
         return <LoadingOverlay />
      }
   }

   return children
}
