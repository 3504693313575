import { UserRoles } from '../common/utils/roles'
import { Paths } from './structure'

export type Route = {
   flags?: string[]
   path: Paths | string
   roles: UserRoles[]
}

export type Routes = {
   [key: string]: Route
}

export const ROUTES = {
   audits: {
      flags: ['AuditEnabled'],
      path: Paths.AUDITS,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   connections: {
      path: Paths.CONNECTIONS,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN, UserRoles.EMPLOYEE],
   },
   dashboard: {
      path: Paths.DASHBOARD,
      roles: [
         UserRoles.SUPERUSER,
         UserRoles.COMPANY,
         UserRoles.ADMIN,
         UserRoles.EMPLOYEE,
      ],
   },
   discounts: {
      path: Paths.DISCOUNTS,
      roles: [UserRoles.SUPERUSER],
   },
   forgotten_password: {
      path: Paths.FORGOTTEN_PASSWORD,
      roles: [],
   },
   global_prices: {
      path: Paths.GLOBAL_PRICES,
      roles: [UserRoles.SUPERUSER],
   },
   history: {
      path: Paths.HISTORY,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   history_audit: {
      flags: ['AuditEnabled'],
      path: Paths.HISTORY_AUDIT,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   history_costing: {
      path: Paths.HISTORY_COSTING,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   help: {
      path: Paths.HELP,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN, UserRoles.EMPLOYEE],
   },
   help_search: {
      path: Paths.HELP_SEARCH,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN, UserRoles.EMPLOYEE],
   },
   help_contact: {
      path: Paths.HELP_CONTACT,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN, UserRoles.EMPLOYEE],
   },
   help_asset_tags: {
      path: Paths.HELP_ASSET_TAGS,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN, UserRoles.EMPLOYEE],
   },
   actions: {
      path: Paths.ACTIONS,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN, UserRoles.EMPLOYEE],
   },
   // TODO: restore commented out routes
   // actions_loan: {
   //     path: Paths.ACTIONS_LOAN,
   //     roles: [
   //         UserRoles.COMPANY,
   //         UserRoles.ADMIN,
   //     ],
   // },
   // actions_return: {
   //     path: Paths.ACTIONS_RETURN,
   //     roles: [
   //         UserRoles.COMPANY,
   //         UserRoles.ADMIN,
   //         UserRoles.EMPLOYEE,
   //     ],
   // },
   // actions_field_transfer: {
   //     path: Paths.ACTIONS_FIELD_TRANSFER,
   //     roles: [
   //         UserRoles.COMPANY,
   //         UserRoles.ADMIN,
   //     ],
   // },
   // actions_warehouse_transfer: {
   //     path: Paths.ACTIONS_WAREHOUSE_TRANSFER,
   //     roles: [
   //         UserRoles.COMPANY,
   //         UserRoles.ADMIN,
   //     ],
   // },
   // actions_borrow: {
   //     path: Paths.ACTIONS_BORROW,
   //     roles: [
   //         UserRoles.EMPLOYEE,
   //     ],
   // },
   items_old: {
      path: Paths.ITEMS_OLD,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   items: {
      path: Paths.ITEMS,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN, UserRoles.EMPLOYEE],
   },
   items_massTransfer: {
      path: Paths.MASS_TRANSFER,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   landing: {
      path: Paths.LANDING,
      roles: [],
   },
   locations: {
      flags: ['EnableLocationTracking'],
      path: Paths.LOCATIONS,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   login: {
      path: Paths.LOGIN,
      roles: [],
   },
   logout: {
      path: Paths.LOGOUT,
      roles: [],
   },
   404: {
      path: Paths.NOTFOUND,
      roles: [],
   },
   notes: {
      path: Paths.NOTES,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   password_reset: {
      path: Paths.PASSWORD_RESET,
      roles: [],
   },
   profile: {
      path: Paths.MY_PROFILE,
      roles: [
         UserRoles.SUPERUSER,
         UserRoles.COMPANY,
         UserRoles.ADMIN,
         UserRoles.EMPLOYEE,
      ],
   },
   profile_reset_password: {
      path: Paths.MY_PROFILE_RESET_PASSWORD,
      roles: [
         UserRoles.SUPERUSER,
         UserRoles.COMPANY,
         UserRoles.ADMIN,
         UserRoles.EMPLOYEE,
      ],
   },
   profile_accountSettings: {
      path: Paths.MY_PROFILE_ACCOUNT_SETTINGS,
      roles: [UserRoles.SUPERUSER, UserRoles.COMPANY],
   },
   profile_subscriptions: {
      path: Paths.MY_PROFILE_SUBSCRIPTIONS,
      roles: [UserRoles.COMPANY],
   },
   settings: {
      path: Paths.SETTINGS,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   settings_categories: {
      path: Paths.SETTINGS_CATEGORIES,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   settings_itemRecord: {
      path: Paths.SETTINGS_ITEMS,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   settings_manufacturers: {
      path: Paths.SETTINGS_MANUFACTURERS,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   settings_noteTypes: {
      path: Paths.SETTINGS_NOTES,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   settings_notifications: {
      path: Paths.SETTINGS_NOTIFICATIONS,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN],
   },
   subscriptions: {
      path: Paths.SUBSCRIPTIONS,
      roles: [UserRoles.SUPERUSER],
   },
   users: {
      path: Paths.USERS,
      roles: [UserRoles.SUPERUSER],
   },
   register: {
      path: Paths.REGISTER,
      roles: [],
   },
   register_trial: {
      path: Paths.REGISTER_TRIAL,
      roles: [],
   },
   register_trial_confirmation: {
      path: Paths.REGISTER_TRIAL_CONFIRMATION,
      roles: [],
   },
}

// returns a route object by path
export const getRouteByPath = (path: string): Route =>
   Object.values(ROUTES).find((route) => route.path === path)

// returns the roles for a route by path
export const getRolesByRoute = (path: string): UserRoles[] => {
   const route = getRouteByPath(path)
   return route?.roles || []
}
