import React from 'react'
import {
   useTable,
   usePagination,
   useResizeColumns,
   useBlockLayout,
} from 'react-table'
import Pagination from '../../../components/common/pagination/pagination'
import { Table, Tile } from '.'
import { Alert } from '../../common'
import { Severity } from '../../../types'
import { TABLE } from '../../../constants/tables'
import * as actionsPageStyles from '../../views/actions/actionsPage.module.scss'
import { TableWrapperProps } from './types'

export const TableWrapper = ({
   data = [],
   columns = [],
   totalCount = 0,
   getItems,
   takeItems,
   skipItems,
   setSearchMode,
   searchMode,
   isPageReset,
   setIsPageReset,
   setPageSized,
   extraClassName,
   isLoading,
   searchCriteria,
   viewValue = 2,
   initialState = {},
   toolStatus,
   hidePagination,
   setBypass,
   getItem,
   isListLoading,
   isListRefetching,
   hideResultsTitle = false,
   isScrollView = false,
   isCart = false,
}: TableWrapperProps) => {
   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      setPageSize,
      state: { pageSize },
   } = useTable(
      {
         columns,
         data,
         initialState,
      },
      usePagination,
      useResizeColumns,
      useBlockLayout
   )

   return (
      <>
         {!isLoading && Boolean(!totalCount) && (
            <div
               className={
                  isScrollView
                     ? actionsPageStyles.itemSelectionTableContainer
                     : null
               }
            >
               <Alert
                  message={isCart ? TABLE.NO_RESULTS_CART : TABLE.NO_RESULTS}
                  severity={Severity.INFO}
               />
            </div>
         )}
         {Boolean(totalCount) && (
            <div className={'table-container ' + extraClassName}>
               {viewValue === 1 && (
                  <Tile
                     totalCount={totalCount}
                     data={data}
                     setBypass={setBypass}
                     getItem={getItem}
                     isListLoading={isListLoading}
                     isListRefetching={isListRefetching}
                  />
               )}
               {viewValue === 2 && (
                  <>
                     <div
                        className={
                           isScrollView
                              ? actionsPageStyles.itemSelectionTableContainer
                              : null
                        }
                     >
                        <Table
                           totalCount={totalCount}
                           getTableProps={getTableProps}
                           headerGroups={headerGroups}
                           getTableBodyProps={getTableBodyProps}
                           page={page}
                           prepareRow={prepareRow}
                           rowAction={getItem}
                           hideResultsTitle={hideResultsTitle}
                           isCart={isCart}
                        />
                     </div>
                     {isScrollView && <br />}
                  </>
               )}
               {!hidePagination && (
                  <Pagination
                     totalCount={totalCount}
                     setPageSize={setPageSize}
                     setPageSized={setPageSized}
                     pageSize={pageSize}
                     getItems={getItems}
                     takeItems={takeItems}
                     skipItems={skipItems}
                     setSearchMode={setSearchMode}
                     searchMode={searchMode}
                     isPageReset={isPageReset}
                     setIsPageReset={setIsPageReset}
                     searchCriteria={searchCriteria}
                     toolStatus={toolStatus}
                     pageDataLength={page?.length}
                  />
               )}
            </div>
         )}
      </>
   )
}
