import { ToolStatus } from '../../../types'

export const ACTION_CONSTANTS = {
   quickFilterOptions: [
      {
         id: 0,
         label: 'Loan',
         status: ToolStatus.AVAILABLE,
         icon: 'actionLoan',
      },
      {
         id: 1,
         label: 'Return',
         status: ToolStatus.LOANED,
         icon: 'actionReturn',
      },
      {
         id: 2,
         label: 'Field Transfer',
         status: ToolStatus.LOANED,
         icon: 'actionTransfer',
      },
      {
         id: 3,
         label: 'Warehouse Transfer',
         status: ToolStatus.AVAILABLE,
         icon: 'actionTransfer',
      },
   ],
   quickFilterEmployeeOptions: [
      {
         id: 0,
         label: 'Borrow',
         status: ToolStatus.AVAILABLE,
         icon: 'actionLoan',
      },
      {
         id: 1,
         label: 'Return',
         status: ToolStatus.EMPLOYEE_RETURN,
         icon: 'actionReturn',
      },
   ],
   companyCheckoutOptions: [
      {
         id: 0,
         label: 'Loan',
         status: ToolStatus.AVAILABLE,
         icon: 'actionLoan',
      },
      {
         id: 1,
         label: 'Return',
         status: ToolStatus.LOANED,
         icon: 'actionReturn',
      },
      {
         id: 2,
         label: 'Field Transfer',
         status: ToolStatus.LOANED,
         icon: 'actionTransfer',
      },
      {
         id: 3,
         label: 'Warehouse Transfer',
         status: ToolStatus.AVAILABLE,
         icon: 'actionTransfer',
      },
      {
         id: 4,
         label: 'Make Available',
         status: ToolStatus.UNAVAILABLE,
         icon: 'makeAvailable',
      },
      {
         id: 5,
         label: 'Make Unavailable',
         status: ToolStatus.AVAILABLE,
         icon: 'makeUnavailable',
      },
   ],
   employeeCheckoutOptions: [
      {
         id: 0,
         label: 'Borrow',
         status: ToolStatus.AVAILABLE,
         icon: 'actionLoan',
      },
      {
         id: 1,
         label: 'Return',
         status: ToolStatus.EMPLOYEE_RETURN,
         icon: 'actionReturn',
      },
      {
         id: 2,
         label: 'Field Transfer',
         status: ToolStatus.LOANED,
         icon: 'actionTransfer',
      },
   ],
   defaultColumnOrder: [
      'CTA',
      'PictureURL',
      'Title',
      'ToolType',
      'AssignedToKitName',
      'Quantity',
      'StatusQuantity',
      'StatusDesc',
      'AssignedToUser',
      'Description',
      'Manufacturer',
      'ModelNumber',
      'SerialNumber',
      'Barcode',
      'Category',
      'PurchaseDate',
      'PurchasePrice',
      'TotalValue',
      'Vendor',
      'WarrantyDate',
      'RentalItem',
      'RentalDate',
      'RentalReturnDate',
      'CustomRentalRate1',
      'CustomRentalRate2',
      'CustomRentalRate3',
      'CustomRentalRate4',
      'CustomDate1Value',
      'CustomDate2Value',
      'CustomText1Value',
      'CustomText2Value',
      'CustomURL1Value',
      'CustomURL2Value',
      'WebSiteURL',
      'AttachmentUrl',
      'DefaultLocation',
      'CurrentLocation',
      'GpsCreatedOn',
      'GpsCreatedByName',
      'LastAuditDate',
      'LastAuditConditionDesc',
      'CreatedDate',
   ],
}
