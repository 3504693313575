import React, { useEffect, useRef, useState } from 'react'
import {
   DragDropContext,
   Draggable,
   Droppable,
   DropResult,
} from 'react-beautiful-dnd'

import { IconButton } from '..'
import { Dropdown } from '../form/fields'
import { Option } from '../form/fields/dropdown/dropdown.type'
import { useSettings } from '../../../hooks'

import { Item, ItemReorderProps } from './itemReorder.type'
import * as styles from './itemReorder.module.scss'

const ItemReorder = ({
   defaultItems,
   items,
   onChange,
   permissions = {},
}: ItemReorderProps) => {
   const [itemList, setItemList] = useState<Item[]>(items)
   const [selectedItem, setSelectedItem] = useState<Option>(null)
   const dropdownRef = useRef()
   const { settings } = useSettings()

   const handleDrop = (droppedItem: DropResult) => {
      // Ignore drop outside droppable container
      if (!droppedItem.destination) return

      var updatedList = [...itemList]
      // Remove dragged item
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)

      // Add dropped item
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem)

      // Update State
      setItemList(updatedList)
   }

   const handleDelete = (itemKey: string) => {
      const updatedList = itemList.filter((item) => item.value !== itemKey)

      setItemList(updatedList)
   }

   const handleAddColumn = () => {
      setItemList([selectedItem, ...itemList])
      setSelectedItem(null)

      if (dropdownRef && dropdownRef?.current && dropdownRef.current.Select) {
         dropdownRef?.current?.Select?.clearValue()
      }
   }

   const handleSelectColumn = (option: Option) => {
      setSelectedItem(option)
   }

   useEffect(() => {
      onChange(itemList.map((item) => item.value))
   }, [itemList])

   useEffect(() => {
      const filteredList = items.filter((item, index) => {
         if (!permissions.hasOwnProperty(item.value)) return true
         return !!settings[permissions[item.value]]
      })
      setItemList(filteredList)
   }, [items])

   const addDropdownOptions = defaultItems.reduce((options, item) => {
      if (
         permissions.hasOwnProperty(item.value) &&
         !settings[permissions[item.value]]
      ) {
         return options
      }

      return itemList.find((i) => i.value === item.value)
         ? options
         : [...options, { label: item.label, value: item.value }]
   }, [] as Item[])

   const hasColumnsAvailable = defaultItems.length === itemList.length

   return (
      <div>
         <div className={styles.addWrapper}>
            <div className={styles.addField}>
               <Dropdown
                  name="add-column"
                  // @ts-ignore
                  onChange={handleSelectColumn}
                  options={addDropdownOptions}
                  defaultValue={[selectedItem]}
                  id="add-column"
                  label={
                     !hasColumnsAvailable
                        ? 'Add column'
                        : 'No columns available'
                  }
                  disabled={hasColumnsAvailable}
                  ref={dropdownRef}
               />
            </div>
            <div className={styles.addBtn}>
               <IconButton
                  disabled={!selectedItem}
                  id="plus"
                  action={handleAddColumn}
               />
            </div>
         </div>

         <hr />

         <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="list">
               {(provided) => (
                  <div
                     className="list"
                     {...provided.droppableProps}
                     ref={provided.innerRef}
                  >
                     {itemList?.length > 0 &&
                        itemList.map((item, index) => (
                           <Draggable
                              key={item.value}
                              draggableId={item.value}
                              index={index}
                           >
                              {(provided) => (
                                 <div
                                    className={styles.item}
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                 >
                                    <span className={styles.label}>
                                       {item.label}
                                    </span>
                                    <span className={styles.deleteBtn}>
                                       <IconButton
                                          id="delete"
                                          action={() =>
                                             handleDelete(item.value)
                                          }
                                       />
                                    </span>
                                 </div>
                              )}
                           </Draggable>
                        ))}
                     {provided.placeholder}
                  </div>
               )}
            </Droppable>
         </DragDropContext>
      </div>
   )
}

export default ItemReorder
