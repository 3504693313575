import React, {useEffect, useRef} from 'react';

import {useNotifications} from '../../../hooks';
import { Button, LoadingOverlay, NoResults } from '..';
import NotificationItem from './notificationItem/notificationItem';
import {Notification } from '../../../types';

import {Props} from './notificationList.type';
import * as styles from './notificationList.module.scss';

const NotificationList: React.FC<Props> = ({ hideAcceptDecline = false, hideDismiss = false, onUpdate, total, type }) => {
    const { acceptReject, dismiss, isLastPage, isLoading, isLoadingMore, list, nextPage } = useNotifications({
        onChange: onUpdate,
        totalItems: total,
        type,
    });
    const contentRef = useRef<HTMLDivElement>(null);

    const handleDismiss = (id: Notification['Id']) => {
        dismiss(id);
    }

    const handleAcceptDecline = (id: Notification['Id'], accept: boolean) => {
        acceptReject(id, accept);
    }

    useEffect(() => {
        if (contentRef) {
            contentRef.current.scrollTo(0, 0);
        }
    }, [])

    return (
        <div className={styles.wrapper} ref={contentRef}>
            {isLoading && !isLoadingMore && <LoadingOverlay positionAbsolute small />}

            {list.map(item => (
                <NotificationItem
                    key={item.Id}
                    onAcceptDecline={!hideAcceptDecline && handleAcceptDecline}
                    onDismiss={!hideDismiss && handleDismiss}
                    notification={item}
                />
            ))}

            {!!list.length && !isLastPage && (
                <div className={styles.loadMore}>
                    <Button
                        onClick={nextPage}
                        variant='tertiary'
                        small
                        loading={isLoadingMore}

                    >
                        Load more
                    </Button>
                </div>
            )}

            {!isLoading && !list.length && (
                <NoResults>
                    <p>No tasks to show</p>
                </NoResults>
            )}

        </div>
    );
};

export default NotificationList;