import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';

import { useAPI } from '..';

export interface UseWorkflow {
    isPostingRequest: boolean,
    postWorkflowRequest: (path: string, workflowOptions: any) => void;
    isWorkflowPostSuccess: boolean,
}


export default function(): UseWorkflow {
    const [_error, setErrors] = useState('');
    const request = useAPI();
    const [path, setPath] = useState('');
    const [workflowRequestOptions, setWorkflowRequestOptions] = useState(null)

    useEffect(() => {
        if(path && workflowRequestOptions) {
            updateLoanBorrowRequest(workflowRequestOptions);
        }
    }, [path, workflowRequestOptions])


    
    const { isLoading: isPostingRequest, isSuccess: isWorkflowPostSuccess, mutate: updateLoanBorrowRequest } = useMutation(initiateLoanBorrowRequest, {
        onSuccess: () => {
            setErrors('');
            setPath('');
        },
        onError: (error: AxiosError<{ Message: string }>) => {
            setErrors(error.response.data.Message || 'There was an unknown server error');
        }
    });

    async function initiateLoanBorrowRequest(workflowRequestOptions: any): Promise<AxiosResponse> { // update any
        return await request.post(path, workflowRequestOptions);
    }

    const postWorkflowRequest = (path: string, workflowOptions: any) => {
        setErrors('');
        setPath(path);
        setWorkflowRequestOptions(workflowOptions);
    }

    return {
        isPostingRequest,
        postWorkflowRequest,
        isWorkflowPostSuccess
    };
};