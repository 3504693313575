import { useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { UseLocations, LocationsResponse } from './useLocations.type'

export default function (): UseLocations {
   const request = useAPI()
   const { data, isLoading, refetch, isRefetching } = useQuery(
      'locations',
      getLocationsFromAPI
   )
   const {
      error,
      isLoading: isImporting,
      isSuccess: isImportSuccess,
      mutate,
   } = useMutation(importLocationsFile)

   async function getLocationsFromAPI(): Promise<
      AxiosResponse<LocationsResponse>
   > {
      try {
         return await request.get(PATH.LOCATIONS.LIST)
      } catch (error) {
         // TODO: handle errors better
         console.log(error)
      }
   }

   async function importLocationsFile(file: File): Promise<AxiosResponse<any>> {
      const formData = new FormData()
      formData.append('file', file)

      return await request.post(PATH.LOCATIONS.IMPORT, formData, {
         headers: {
            'Content-Type': 'multipart/form-data',
         },
      })
   }

   const importLocations = async (file: File) => {
      await mutate(file)
      refetch()
   }

   return {
      // @ts-ignore
      error: error?.response?.data?.Message as string,
      importLocations,
      isImporting,
      isImportSuccess,
      isLoading: isLoading || isRefetching,
      list: data?.data.Locations || [],
   }
}
