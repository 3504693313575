import { useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {AxiosResponse} from 'axios';
import { PATH } from '../../constants/global';

import {UseTableData, AuditHistoryOptions} from '../../types';
import {useAPI} from '../';

export default function (): UseTableData {
    const request = useAPI();
    const [pageOptions, setPageOptions] = useState('');
    const cacheIdentifier = 'item-history'

    async function getItemsFromAPI(options: string): Promise<AxiosResponse> {
        if (options) {
            return request.get(`${PATH.ITEMS_HISTORY.LIST}?id=${options}`);
        }
    };

    const {
        data,
        refetch,
        isLoading,
        isRefetching,
    } = useQuery<Promise<AxiosResponse>>(
        [cacheIdentifier],
        () => getItemsFromAPI(pageOptions)
    );

    const itemHistoryList = (str) => {
        setPageOptions(str);
    }

    useEffect(() => {
        refetch();
    }, [pageOptions]);

    return {
        data: data?.data,
        itemHistoryList,
        isLoading,
        isRefetching
    }
}
