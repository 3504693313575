import { Dispatch, ReactNode, SetStateAction } from 'react'

export type ImportItemsModalProps = {
   isOpen: boolean
   handleClose: () => void
   uploadPercentage: number
   setUploadPercentage: Dispatch<SetStateAction<number>>
   currentView: ImportItemModalViews
   setCurrentView: Dispatch<SetStateAction<ImportItemModalViews>>
   importSelectedFile: (file: File, addImagesWithAI?: boolean) => void
   handleSuccessfulImport: () => void
   isImportingData: boolean
   importError: string
   importErrorFile: string[]
   isImportingSuccess: boolean
   totalItemsImported: number
   totalImagesImported: number
}

export enum ImportItemModalViews {
   IMPORT_OPTIONS = 1,
   IMPORTING = 2,
   SUCCESS = 3,
   ERROR = 4,
}

export type NumberedSectionProps = {
   num: number
   heading: ReactNode
   description: ReactNode
   action: ReactNode
}

export type UploadButtonProps = {
   selectedFile: File | null
   setSelectedFile: Dispatch<SetStateAction<File>>
}

export type AddWithAIToggleProps = {
   addImagesWithAI: boolean
   setAddImagesWithAI: Dispatch<SetStateAction<boolean>>
}

export type ImportOptionsViewProps = UploadButtonProps & AddWithAIToggleProps

export type ImportStatusViewProps = {
   addImagesWithAI: boolean
   currentView: ImportItemModalViews
   uploadPercentage: number
   importError: string
   importErrorFile: string[]
   totalItemsImported: number
   totalImagesImported: number
}
