import React, { useState, useEffect, useRef, useMemo } from 'react'
import * as styles from './crudItems.module.scss'
import * as stylesCrudForm from '../crud/crudForm.module.scss'
import { Col, Row } from 'react-bootstrap'
import { Actions, TABLE } from '../../../constants/tables'
import { CrudItemsProps } from './crudItems.type'
import {
   RadioButtonContainer,
   RadioButton,
   Button,
   Modal,
   Svg,
} from '../../common'
import { ItemDetails, Rental, OtherDetails, Notes, History } from './'
import { useSettings } from '../../../hooks'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { LoadingOverlay } from '../../common'
import { ToolTypes } from '../../../types'
import { ItemGPSView } from './itemGPSView/itemGPSView'

const CrudItems: React.FunctionComponent<CrudItemsProps> = ({
   isNewItemNote,
   setIsNewItemNote,
   postItem,
   itemImages,
   setItemImages,
   changePosition,
   deleteImage,
   isPositionLoading,
   isPositionSuccess,
   setItemStatus,
   itemStatus,
   activeValue,
   isLoading,
   itemTypes,
   categories,
   manufacturers,
   locations,
   conditions,
   itemNotesData,
   itemHistoryData,
   handleAction,
   closeModal,
   itemAction,
   setItemAction,
   itemId,
   isModalVisible,
   setItemForm,
   itemForm,
   resetRef,
   getItemView,
   refetchNoteTableData,
   handleAddOrRemoveFromCart,
   isItemAlreadyAddedToCart,
   isEmployeeUserViewingItemsPage,
   parentPageView = null,
   handleWorkflowAction,
}) => {
   const { settings } = useSettings()
   const [toggleValue, setToggleValue] = useState(
      Boolean(itemForm.IsRentalTool)
   ) // changed from item settings
   const [isSecondaryModalVisible, setIsSecondaryModalVisible] = useState(false)
   const [isTitleError, setIsTitleError] = useState<boolean | string>('')

   const [isQuantityOrConsumableToolType, setIsQuantityOrConsumableToolType] =
      useState(false)
   const [isQuantityItem, setIsQuantityItem] = useState(false)

   const isItemCurrentlyInActionCart = useMemo(() => {
      if (isItemAlreadyAddedToCart) {
         return isItemAlreadyAddedToCart(itemForm.localUniqueId)
      }
      return false
   }, [itemForm])

   useEffect(() => {
      setIsQuantityOrConsumableToolType(
         activeValue?.ToolType == ToolTypes['Quantity Item'] ||
            activeValue?.ToolType == ToolTypes['Consumable']
      )
      setIsQuantityItem(activeValue?.ToolType == ToolTypes['Quantity Item'])
   }, [activeValue])

   useEffect(() => {
      if (itemAction === Actions.Add) {
         setIsQuantityOrConsumableToolType(
            itemForm.ToolType == ToolTypes['Quantity Item'] ||
               itemForm.ToolType == ToolTypes['Consumable']
         )
         setIsQuantityItem(itemForm.ToolType == ToolTypes['Quantity Item'])
      }
   }, [itemForm])

   useEffect(() => {
      // if employeeUser on items page and are not adding an item -> override any other action to become readonly
      if (
         isEmployeeUserViewingItemsPage &&
         setItemAction &&
         itemAction !== Actions.Add
      ) {
         setItemAction(Actions.Readonly)
      }
   }, [])

   const handleSave = () => {
      updateRemoveAddImageFile()

      if (!itemForm?.Title) {
         setIsTitleError(true)
      } else {
         handleAction(itemAction, itemId, itemForm)
         resetRef.current.click()
         closeModal()
      }
   }

   const closeSecondaryModal = () => {
      setIsSecondaryModalVisible(false)
      resetRef.current.click()
   }

   const closeAllModals = () => {
      setIsSecondaryModalVisible(false)
      resetRef.current.click()
      closeModal()
   }

   const handleToggle = () => {
      setItemForm({
         ...itemForm,
         IsRentalTool: !toggleValue,
      })
      setToggleValue(!toggleValue)
   }

   const [updateAction, setUpdateAction] = useState<any>({})

   const updateForm = (key, value) => {
      setItemForm({
         ...itemForm,
         [key]: value,
      })
      setUpdateAction({ key, value, itemForm })
   }

   const updateFormSelect = (obj: {}) => {
      setUpdateAction({ obj })
   }

   useEffect(() => {
      const { key, value, obj } = updateAction
      if (!updateAction.hasOwnProperty('key')) {
         // @ts-ignore
         setItemForm({
            ...itemForm,
            ...obj,
         })
      }

      if (
         (updateAction.hasOwnProperty('key') &&
            key === 'Title' &&
            value !== '') ||
         key !== 'Title'
      ) {
         setIsTitleError('')
         // @ts-ignore
         setItemForm({
            ...itemForm,
            ...obj,
            [key]: value,
         })
      } else {
         setIsTitleError('Title field is required')
      }
   }, [updateAction])

   const radioItemStatus = [
      { label: 'Details', status: 0 },
      { label: 'Images', status: 1 },
      { label: 'Other', status: 2 },
      { label: 'Rental', status: 3 },
      { label: 'Notes', status: 4 },
      { label: 'History', status: 5 },
      { label: 'Map', status: 6 },
   ]
   const [radioItems, _setRadioItems] = useState(radioItemStatus)

   const maxItems = 5
   const imageSize = 100

   const [isItems, setIsItems] = useState(true)

   const { PictureURLs } = itemForm

   const [shadowPictureUrlsList, setShadowPictureUrlsList] = useState(
      PictureURLs ?? []
   ) // used to track image urls

   const randomString = () => {
      return Math.random().toString(36).slice(2)
   }

   // START: Drag image
   const onDragEnd = (result: {
      destination: { index: number }
      source: { index: number }
   }) => {
      if (!result.destination) {
         return
      }

      const _items = reorder(
         itemImages,
         result.source.index,
         result.destination.index
      )

      const _urls = reorder(
         shadowPictureUrlsList,
         result.source.index,
         result.destination.index
      )

      setShadowPictureUrlsList(_urls)

      setItemImages(_items)
   }
   // END: Drag image

   const resetImageShuffle = () => {
      // setIsImageAdding(false)
      setIsButtonDisabled(false)
   }

   const handleShuffle = () => {
      getItemView({
         Id: itemForm.Id,
         ToolLoanId: itemForm.ToolLoanId,
         KitId: itemForm.KitId,
      })
      setTimeout(resetImageShuffle, 500)
   }

   useEffect(() => {
      if (itemImages.length && isPositionSuccess === true) {
         // setIsImageAdding(true)
         setIsButtonDisabled(true)
         handleShuffle()
      }
   }, [isPositionSuccess])

   const [deleteImageData, setDeleteImageData] = useState([])
   const [existingPositions, setExistingPositions] = useState(
      PictureURLs?.map((url) => url.Position) ?? []
   )

   const removeItemImage = (id: number, index: number) => {
      const deleteByPosition = itemImages
         .map((image) => {
            if (image.Id === id) return image.OriginalPosition
         })
         .filter(Number)

      setDeleteImageData([
         ...deleteImageData,
         deleteByPosition.length !== 0 ? deleteByPosition : [0],
      ])

      const updatedItemImages = itemImages
         .filter((item) => item.Id !== id)
         .map((image, index) => {
            // update image positions after removing
            image.Position = index
            return image
         })

      const updatedUrls = shadowPictureUrlsList
         .filter((url, urlIndex) => urlIndex !== index)
         .map((url, urlIndex) => {
            url.Position = urlIndex
            if (urlIndex === 0) {
               url.IsPrimary = true
            }
            return url
         })

      const arr = actionList.filter((el) => {
         return id !== el.uiId
      })

      setActionList(arr)
      setItemImages(updatedItemImages)
      setShadowPictureUrlsList(updatedUrls)
   }

   const reorder = (
      list: Iterable<any> | ArrayLike<any>,
      startIndex: number,
      endIndex: number
   ) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      const processed = result.map((item, index) => {
         return {
            ...item,
            Position: index,
         }
      })
      return processed
   }

   const handleChangeFile = (file: Blob) => {
      const fileData = new FileReader()
      fileData.readAsDataURL(file)
      fileData.onloadend = (e) => handleFile(e, file)
   }

   const canvasRef = useRef()
   const [actionList, setActionList] = useState<any[]>([{}])

   // START: File conversion
   const handleFile = async (e: ProgressEvent<FileReader>, file: Blob) => {
      const nextItem = `item-${itemImages.length + 1}-${randomString()}`
      const item = e.currentTarget.result // base64
      const mimetype = item.match(new RegExp('data:' + '(.*)' + ';base64'))[1]

      const File0 = file
      const thumbName = 'thumb_' + File0['name']

      function dataURLtoFile(dataurl, filename) {
         const arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1])
         let n = bstr.length
         const u8arr = new Uint8Array(n)

         while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
         }

         return new File([u8arr], filename, { type: mime })
      }

      const ctx = canvasRef.current.getContext('2d')

      function draw(ctx) {
         ctx.clearRect(0, 0, ctx.width, ctx.height)
         requestAnimationFrame(() => draw(ctx))
      }

      requestAnimationFrame(() => draw(ctx))

      const url = URL.createObjectURL(file)

      const img = new Image()

      img.src = url
      img.height = 300
      img.width = 300
      img.onload = function () {
         ctx.drawImage(img, 0, 0, 300, 300)
         const base64Full = ctx.canvas.toDataURL()

         const file = dataURLtoFile(base64Full, thumbName)
         setItemImages([
            // show on the screen
            ...itemImages,
            {
               justAdded: true,
               Id: nextItem,
               Toolid: itemForm.Id,
               Position: itemImages.length,
               FullUrl: item,
               PictureUrl: item,
               MimeType: mimetype,
               ThumbnailUrl: item,
               'File[0]': File0,
               'File[1]': file,
            },
         ])
      }
   }
   // END: File conversion

   const [isButtonDisabled, setIsButtonDisabled] = useState(false)

   // START: Handle existing files on load
   const handleExistingImages = (obj: {
      FullUrl: string
      PictureUrl: string
      MimeType: string
      ThumbnailUrl: string
      Id: number
      Position: number
   }) => {
      const nextItem = `item-${itemImages.length + 1}-${randomString()} `
      const _items = itemImages
      _items.push({
         Id: nextItem,
         FullUrl: obj.FullUrl,
         PictureUrl: obj.PictureUrl,
         MimeType: obj.MimeType,
         Position: obj.Position,
         ThumbnailUrl: obj.ThumbnailUrl,
         OriginalId: obj.Id,
         OriginalPosition: obj.Position,
      })
      setItemImages([..._items])
   }
   // END: Handle existing files on load

   useEffect(() => {
      setItemForm({
         ...itemForm,
         itemImages: remapId(itemImages),
      })

      itemImages.length <= maxItems ? setIsItems(true) : setIsItems(false)
   }, [itemImages])

   const inputRef = useRef()

   const handleInput = () => {
      inputRef?.current.click()
   }

   const remapId = (arr: []) => {
      return arr.map((item: {}, index: number) => {
         return {
            ...item,
            Id: index,
            Position: index,
         }
      })
   }

   const handleSecondaryModal = () => {
      closeAllModals()
   }

   useEffect(() => {
      if (PictureURLs && !itemForm.copyId) {
         PictureURLs.map((obj) => {
            handleExistingImages(obj)
         })
      }
   }, [PictureURLs])

   const removeImage = () => {
      const toolId = itemForm.Id
      const deleteImageDataFlat = deleteImageData.flat()
      deleteImageDataFlat.forEach((position) => {
         deleteImage({ toolId, position: existingPositions[position] })
      })
   }

   const updateRemoveAddImageFile = () => {
      if (!itemForm.copyId) {
         // update any positions
         if (shadowPictureUrlsList.length && itemForm.Id) {
            const positionsToUpdate = shadowPictureUrlsList.map((url) => ({
               Id: url.Id,
               Position: url.Position,
            }))
            changePosition({
               toolId: itemForm.Id,
               images: positionsToUpdate,
            })
         }
         // if any image deleted remove
         if (deleteImageData.length) {
            removeImage()
         }

         // if any image added add
         if (itemForm.Id) {
            itemImages.forEach((image) => {
               if (image.justAdded) {
                  postItem(
                     {
                        Toolid: itemForm.Id ? itemForm.Id : null,
                        Position: image.Position,
                        'File[0]': image['File[0]'],
                        'File[1]': image['File[1]'],
                     },
                     itemForm.Id
                  )
               }
            })
         }
      }
   }

   const crudItemsPrimaryButtonMessage = useMemo(() => {
      if (itemAction !== Actions.Readonly) {
         return 'Save'
      } else if (
         itemAction === Actions.Readonly &&
         parentPageView === TABLE.TITLE.ACTIONS &&
         isItemCurrentlyInActionCart
      ) {
         return 'Remove From Cart'
      } else if (
         itemAction === Actions.Readonly &&
         parentPageView === TABLE.TITLE.ACTIONS &&
         !isItemCurrentlyInActionCart
      ) {
         return 'Add to Cart'
      } else {
         return 'Save'
      }
   }, [itemForm])

   return (
      <div className={styles.crudItems}>
         <canvas
            ref={canvasRef}
            width={300}
            height={300}
            style={{
               display: 'none',
            }}
         />
         <RadioButtonContainer variant="filter" id={''} groupName={''}>
            {radioItems.map(
               (item: { label: string; status: number }, index: number) => {
                  return (
                     <RadioButton
                        resetRef={index === 0 ? resetRef : null}
                        key={index}
                        id={`${index}radio-item-modal`}
                        groupName="itemsAddEdit"
                        variant="filter"
                        checked={item.status === itemStatus}
                        action={() => setItemStatus(item.status)}
                        hide={
                           itemAction !== Actions.Edit &&
                           itemAction !== Actions.Readonly &&
                           (item.label === 'Notes' || item.label === 'History')
                        }
                        disabled={isLoading}
                     >
                        {item.label}
                     </RadioButton>
                  )
               }
            )}
         </RadioButtonContainer>

         {/*Item Details*/}
         {itemStatus === 0 && isModalVisible && (
            <ItemDetails
               activeValue={activeValue}
               isQuantityOrConsumableToolType={isQuantityOrConsumableToolType}
               isQuantityItem={isQuantityItem}
               itemTypes={itemTypes}
               itemForm={itemForm}
               itemAction={itemAction}
               locations={locations}
               manufacturers={manufacturers}
               categories={categories}
               updateForm={updateForm}
               updateFormSelect={updateFormSelect}
               isTitleError={isTitleError}
               showEditItems={
                  itemAction === Actions.Edit || itemAction === Actions.Readonly
               }
               settings={settings}
               isEmployeeUser={isEmployeeUserViewingItemsPage}
            />
         )}
         {itemStatus === 1 && (
            <>
               {isPositionLoading && <LoadingOverlay />}
               <div
                  className={styles.itemColumns}
                  style={{
                     pointerEvents:
                        itemAction === Actions.Readonly ? 'none' : 'auto',
                  }}
               >
                  {' '}
                  {/* disable any action with pointer events if readonly */}
                  <div className={styles.columnOne}>
                     {itemForm && (
                        <DragDropContext onDragEnd={onDragEnd}>
                           <Droppable
                              droppableId="droppable"
                              direction="horizontal"
                           >
                              {(provided, snapshot) => (
                                 <div
                                    ref={provided.innerRef}
                                    className={stylesCrudForm.addItemsDraggable}
                                    {...provided.droppableProps}
                                 >
                                    {itemImages.map((item, index) => (
                                       <Draggable
                                          key={item.Id}
                                          draggableId={item.Id}
                                          index={index}
                                       >
                                          {(provided, snapshot) => (
                                             <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={
                                                   index === 0
                                                      ? stylesCrudForm.crudItemFirst
                                                      : null
                                                }
                                             >
                                                <div
                                                   className={
                                                      stylesCrudForm.crudItemImage
                                                   }
                                                >
                                                   <span
                                                      onClick={(event) =>
                                                         removeItemImage(
                                                            item.Id,
                                                            index
                                                         )
                                                      }
                                                   >
                                                      <Svg
                                                         id="close"
                                                         overrideViewBox="0,0,8,8"
                                                         height="22"
                                                         width="22"
                                                      />
                                                   </span>
                                                   <img
                                                      alt={item.content}
                                                      src={item.FullUrl}
                                                      width={imageSize}
                                                      height={imageSize}
                                                   />
                                                </div>
                                             </div>
                                          )}
                                       </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    {isItems && (
                                       <div
                                          className={
                                             stylesCrudForm.addImageButton
                                          }
                                          onClick={handleInput}
                                       >
                                          <input
                                             type="file"
                                             accept=".jpg, .png"
                                             ref={inputRef}
                                             disabled={isButtonDisabled}
                                             onChange={(e) =>
                                                handleChangeFile(
                                                   e.target.files[0]
                                                )
                                             }
                                          />
                                          <Svg
                                             id="plus"
                                             width="40"
                                             height="40"
                                          />
                                       </div>
                                    )}
                                 </div>
                              )}
                           </Droppable>
                        </DragDropContext>
                     )}
                  </div>
               </div>
               {itemAction === Actions.Edit && (
                  <div className={styles.placeholder} />
               )}
            </>
         )}

         {itemStatus === 2 && isModalVisible && (
            <>
               <div className={styles.itemColumns}>
                  <div
                     className={styles.columnOne}
                     style={{
                        pointerEvents:
                           itemAction === Actions.Readonly ? 'none' : 'auto',
                     }}
                  >
                     {/* Other tab */}
                     <OtherDetails
                        activeValue={activeValue}
                        itemForm={itemForm}
                        updateForm={updateForm}
                        updateFormSelect={updateFormSelect}
                        settings={settings}
                        isQuantityOrConsumableToolType={
                           isQuantityOrConsumableToolType
                        }
                        conditions={conditions}
                        handleWorkflowAction={handleWorkflowAction}
                        isEmployeeUser={isEmployeeUserViewingItemsPage}
                     />
                  </div>
               </div>
               {itemAction === Actions.Edit && (
                  <div className={styles.placeholder} />
               )}
            </>
         )}
         {itemStatus === 3 && isModalVisible && (
            <>
               <div className={styles.itemColumns}>
                  <div
                     className={styles.columnOne}
                     style={{
                        pointerEvents:
                           itemAction === Actions.Readonly ? 'none' : 'auto',
                     }}
                  >
                     <Rental
                        activeValue={activeValue}
                        itemForm={itemForm}
                        updateForm={updateForm}
                        updateFormSelect={updateFormSelect}
                        settings={settings}
                        toggleValue={toggleValue}
                        handleToggle={handleToggle}
                     />
                  </div>
               </div>
               {itemAction === Actions.Edit && (
                  <div className={styles.placeholder} />
               )}
            </>
         )}
         {itemStatus === 4 && (
            <>
               <div className={styles.note}>
                  <Notes
                     itemId={itemId}
                     toolLoanId={itemForm?.ToolLoanId}
                     itemNotesData={itemNotesData}
                     refetchTableData={refetchNoteTableData}
                     isNewItemNote={isNewItemNote}
                     setIsNewItemNote={setIsNewItemNote}
                     itemAction={itemAction}
                     crudItemsParentPageView={parentPageView}
                  />
               </div>
               {itemAction === Actions.Edit && (
                  <div className={styles.placeholder} />
               )}
            </>
         )}
         {itemStatus === 5 && (
            <>
               <div className={styles.itemColumns}>
                  <History itemHistoryData={itemHistoryData} />
               </div>
               {itemAction === Actions.Edit && (
                  <div className={styles.placeholder} />
               )}
            </>
         )}
         {itemStatus === 6 && (
            <>
               <ItemGPSView selectedItem={itemForm} />
            </>
         )}
         <div className={stylesCrudForm.basicForm}>
            <Row>
               {itemAction !== Actions.Readonly && (
                  <Col>
                     <Button
                        disabled={isTitleError}
                        onClick={
                           // @ts-ignore
                           itemAction !== Actions.Readonly
                              ? handleSave
                              : () =>
                                   handleAddOrRemoveFromCart(
                                      itemForm.localUniqueId
                                   )
                        }
                     >
                        {crudItemsPrimaryButtonMessage}
                     </Button>
                  </Col>
               )}
               <Col>
                  <Button variant="tertiary" onClick={handleSecondaryModal}>
                     Cancel
                  </Button>
               </Col>
            </Row>
         </div>

         <Modal
            isModalVisible={isSecondaryModalVisible}
            closeModal={() => closeSecondaryModal()}
            title="Cancel"
         >
            <div className={stylesCrudForm.basicForm}>
               <p>Are you sure you wish to cancel?</p>
               <Row>
                  <Col>
                     <Button variant="primary" onClick={() => closeAllModals()}>
                        Yes
                     </Button>
                  </Col>
                  <Col>
                     <Button
                        variant="tertiary"
                        onClick={() => closeSecondaryModal()}
                     >
                        No
                     </Button>
                  </Col>
               </Row>
            </div>
         </Modal>
      </div>
   )
}

export default CrudItems
